//restyle angular material components to the mockup
.mat-drawer-backdrop.mat-drawer-shown {
    background-color: transparent !important;
}
.mat-drawer{
  box-shadow: none !important;
}
mat-sidenav:not(.mat-drawer-opened) {
  transform: translate3d(0, 0, 0) !important;
  visibility: visible !important;
  width: 50px !important;
  overflow: hidden;

  .mat-list-item-content{
    padding: 0 0px 0 12px !important;
  }

  img{
    width: 30px;
  }
  
  .nav-caption{
    display: none !important;
  }
  .copyright{
    display: none !important;
  }

}
mat-sidenav-content {
  margin-left: 50px !important;
}
.mat-list-base {
  padding-top: 10px !important;
}
.mat-toolbar-single-row{
  padding:0px !important;
}
a:focus{
  outline:none !important;
}
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  padding: 15px!important;
}
th.mat-header-cell {
  background-color: #e8e8e8;
}
table.mat-table {
  width: 100%;
}
td.mat-cell{
  width: 1% !important;
}
.mat-form-field{
  margin:0px 5px;
}
.mat-form-field-wrapper{
  padding-bottom: 5px;
  margin: 0 0 !important;
}
.mat-button-base{
  vertical-align: super !important;
}
mat-expansion-panel{
  padding: 10px;
}
mat-sidenav.md-closed-add,
mat-sidenav.md-closed-remove {
    -webkit-transition: all .4s cubic-bezier(0.90, .8, .90, 1) !important;
    transition: all .4s cubic-bezier(0.90, .8, .90, 1) !important;
}
.mat-drawer-inner-container::-webkit-scrollbar {
  display: none !important;
}

.table-entity{
  padding: 10px;
  overflow: auto;
  box-sizing: content-box;
    display: block;
    margin: 0;
    border-radius: 4px;
    //overflow: hidden;
    transition: margin 225ms cubic-bezier(0.4, 0, 0.2, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
 }
 td.dynamic-table  .mat-form-field-infix {
  width: 10% !important;
}

td.dynamic-table .mat-form-field-appearance-outline .mat-form-field-outline{
  top: 1.25em !important;
}

.mat-snack-bar-container {
  padding: 0px !important;
}

snack-bar-container.success-snackbar  h3{
  color: green
}

snack-bar-container.error-snackbar h3{
  color: red
}
snack-bar-container.warning-snackbar h3{
  color: orange
}
snack-bar-container.notify-snackbar h3{
  color: orange
}

snack-bar-container.info-snackbar h3{
  color: skyblue;
}

.mat-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
button.primary-color{
  background-color: #016F89;
  color: #fff;
}

mat-form-field .mat-form-field {
  &-underline {
    position: relative;
    bottom: auto;
  }

  &-subscript-wrapper {
    position: static;
  }
}

.formFieldSubscriptWrapperPositionAbsolute mat-form-field .mat-form-field-subscript-wrapper {
  position: absolute !important;
}
