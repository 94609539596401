
// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.
@import './assets/scss/tcoUpdatedTheme';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mat-tco-custom: (
  50: #e0f7fa,
  100: #b2ebf2,
  200: #016F89,
  300: #016F89,
  400: #016F89,
  500: #05A5CB,
  600: #0282A0,
  700: #016F89,
  800: #00838f,
  900: #006064,
  A100:  #016F89,
  A200:  #016F89,
  A400: #016F89,
  A700:  #016F89,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$mat-tco-custom-second: (
    50: #016F89,
    100:#016F89,
    200:#016F89,
    300:#016F89,
    400:#016F89,
    500:#016F89,
    600:#016F89,
    700:#016F89,
    800:#016F89,
    900:#016F89,
    A100:#016F89,
    A200:#016F89,
    A400:#016F89,
    A700:#016F89,
    contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: $black-87-opacity,
        A200: $black-87-opacity,
        A400: $black-87-opacity,
        A700: white,
    )
);

$tco-snt-virtualwarehouse-app-secondary:  mat.define-palette($mat-tco-custom-second);
$tco-snt-virtualwarehouse-app-primary: mat.define-palette($mat-tco-custom);
$tco-snt-virtualwarehouse-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$tco-snt-virtualwarehouse-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$tco-snt-virtualwarehouse-app-theme: mat.define-light-theme((
  color: (
    secondary: $tco-snt-virtualwarehouse-app-secondary,
    primary: $tco-snt-virtualwarehouse-app-primary,
    accent: $tco-snt-virtualwarehouse-app-accent,
    warn: $tco-snt-virtualwarehouse-app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($tco-snt-virtualwarehouse-app-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


